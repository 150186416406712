@import './variables.less';
@import './icons.less';

// Colors
@secondary-cool-gray: #4c5568;
@cool-charcoal: #252d38;
@outlines-30: #dfe0eb;
@focus-destructive: #ffcccc;
@gray-bg-10: #fcfcfd;
@search-input-border: #c6cdd5;
@search-input-placeholder: #9aa4af;
@search-input-icon-color: #5c6570;
@input-border-color: #cecfdf;
@label-color: #8194a2;

@error-color: #cb5252;
@success-color: #60b169;

// Table
@table-border-radius: 5px;
@table-margin: 19px 13px 31px 13px;
@topBarDefaultHeight: 185px;
@filterBarDefaultHeight: 30px;
@form-control-height: 36px;

.calculate-table-height(@topBarHeight: 0px, @filterBarHeight: 0px) {
  @result: calc(100vh - (@topBarHeight + @filterBarHeight));
}

.form-control-label-style() {
  color: @label-color !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}

// General J4 style for jtm-simple-table component
.j4-table-container {
  ::ng-deep jtm-simple-table {
    .table-wrapper {
      border: 1px solid @outlines-30;
      border-radius: @table-border-radius;
      box-shadow: none;

      .search {
        input {
          padding: 8px 16px;
          background: @white;
          border: 1px solid @search-input-border;
          border-radius: 8px;
          min-width: 221px;
          height: 36px;

          &::placeholder {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: @search-input-placeholder;
          }
        }

        svg {
          margin-left: 18px;
          width: 24px;
          height: 24px;
          color: @search-input-icon-color;
        }
      }

      .table-actions-container {
        padding: 0 !important;
        margin: 0 !important;
      }

      .table-controls {
        padding: 0 !important;
        margin: 0 !important;
      }

      .table-top-bar {
        padding: 25px 18px 10px 18px;
      }

      table {
        //height: should be calculated by component
        overflow-y: scroll;

        thead {
          z-index: 1;
          position: sticky;
          top: 0;

          tr {
            border-top: none !important;
          }
        }

        th {
          color: @secondary-cool-gray;
          font-weight: 500 !important;
          font-size: 14px !important;
          line-height: 20px !important;
        }

        tbody {
          .content {
            color: @cool-charcoal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }

      .table-pagination {
        background-color: transparent !important;
        border: none !important;
        padding: 25px 18px;

        jtm-pagination {
          .title {
            margin: 0;
          }

          .title-text,
          .pagination-text {
            font-weight: 400;
            font-size: 14px;
          }
        }
      }
    }

    ::ng-deep app-kyx-dropdown {
      ::ng-deep jtm-multiselect-dropdown {
        .dropdown-overlay {
          height: fit-content !important;
        }
      }
    }
  }

  ::ng-deep jtm-multiselect-dropdown {
    z-index: 0;

    .option {
      margin-bottom: 12px;
    }

    .option:last-child {
      margin-bottom: 0;
    }

    .toggle-icon {
      float: right;
      margin-left: auto;
    }

    .default-label {
      &.checked {
        color: @secondary-cool-gray;
      }

      color: @secondary-cool-gray;
      border: none;
      background-color: transparent;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
    }

    .selected-item {
      display: flex;
      padding: 0 5px;
      background-color: transparent;
      border: none;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: @secondary-cool-gray;

      &:not(.selected-badge) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:not(:last-child) {
        margin-right: 5px;
      }
    }

    .selected-badge {
      width: 40px;
      border: 1px solid @outlines-30;
      border-radius: 4px;
      color: @secondary-cool-gray;
      padding: 4px 8px;
      font-size: 11px;
      line-height: 16px;
    }
  }
}
