@import './variables.less';

.pill {
  font-size: 14px;
  font-weight: normal;
  color: @text-color;
  background-color: @secondary;
  cursor: pointer;
  border-radius: 32px;
  padding: 8px 16px;
  margin: 0;

  &:hover {
    background: @hover;
  }

  &.active {
    background: @primary;
    color: #fff;
  }

  &.disabled,
  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    background: @disabled;
  }
}
