a {
  cursor: pointer;
  color: @brand-primary;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.link {
  cursor: pointer;
}
