.navbar {
  .navbar-default;
}

.navbar .navbar-nav {
  li > a.active,
  li > a:active {
    background: darken(@gray-lighter, 2%);
    border-radius: @border-radius-base / 2;
    box-shadow: 0px 0px 0px 1px darken(@gray-lighter, 10%);
    color: @gray-dark;
  }

  li > a:hover {
    background: darken(@gray-lighter, 1%);
    border-radius: @border-radius-base / 2;
    box-shadow: 0px 0px 0px 1px darken(@gray-lighter, 6%);
  }

  li > a {
    color: darken(@gray, 2%);
  }
}
