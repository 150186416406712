h3.page-title {
  margin-bottom: 20px;
}

@media (max-width: @screen-xs) {
  .page-header {
    margin-top: 0px;
    margin-bottom: @padding-base-vertical * 2;

    h1 {
      font-size: @font-size-mobile * 1.5;
    }
  }
}
