.table.accounts {
  .glyphicon {
    color: @gray-darker;
  }

  .glyphicon-envelope {
    color: #4e4e4e;
  }

  .glyphicon-more-google {
    color: darken(@brand-danger, 10%);
    margin-left: 4px - 2px;
  }

  .glyphicon-more-apple {
    color: #18396d;
  }

  .glyphicon-more-android {
    color: #55a505;
  }

  .glyphicon-refresh {
    color: #818181;
  }

  .glyphicon-ok {
    color: black;
  }

  .glyphicon-plus-sign {
    color: #9f9f9f;
  }
}
