.country-grid {
  display: flex;
  flex-wrap: wrap;

  .country-grid-item {
    display: flex;
    align-items: center;
    width: 33%;
    padding: 4px;

    &:nth-child(2n) {
      background: rgba(0, 0, 0, 0.05);
    }
  }
}
