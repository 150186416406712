.view-columns {
  .max-height-120 {
    max-height: 120px;
  }

  .empty {
    color: @gray-light;
  }

  detail-label,
  detail-value {
    border-bottom: 1px solid @gray-lighter;
    margin-bottom: -1px;
  }

  detail-label {
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  detail-value {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  detail-value.no-overflow {
    text-overflow: clip;
    overflow: visible;
  }

  &.text {
    line-height: @line-height-base * 2;
    padding-bottom: @padding-base-vertical * 1.5;
  }

  &.images {
    line-height: @line-height-base * 2;
  }

  &.images .tiles {
    margin-right: 0;
    border-bottom: 1px solid #eaeaea;
    padding-bottom: @padding-base-vertical * 4;
  }

  &.images .tiles .thumbnail {
    margin: @padding-base-vertical 0 0 @padding-base-vertical;
  }

  &.images .tiles.last {
    border-bottom: none;
    padding-bottom: @padding-base-vertical * 2;
  }

  &.images .thumbnails {
    padding: 0 @padding-base-vertical;

    .thumbnail {
      margin: @padding-base-vertical;
    }
  }

  &.images .thumbnail {
    height: auto;
    cursor: pointer;
    max-width: 100%;
    max-height: 140px;
    display: inline-block;
    margin: 20px;

    &:hover {
      border: 1px solid darken(@thumbnail-border, 20%);
    }
  }
}
