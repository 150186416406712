.actions {
  display: block;

  .btn {
    height: @searchbar-height+2px;
    line-height: @searchbar-height;
    padding: 0px (@searchbar-height / 2);
    border: 1px solid transparent;
    float: none;
    margin-right: -5px;
    border-right: 1px solid @panel-inner-border;
    border-radius: 0;
    box-shadow: none;
  }

  .left {
    border-radius: @border-radius-base 0 0 @border-radius-base;
  }

  .btn-default {
    background: lighten(@breadcrumb-bg, 2%);
    border: 1px solid @panel-inner-border;
  }

  .btn-default:hover {
    background-color: darken(@breadcrumb-bg, 4%);
    border: 1px solid @panel-inner-border;
  }

  .btn-default.disabled,
  .btn-default[disabled] {
    background: @breadcrumb-bg;
    color: darken(@breadcrumb-bg, 10%);
    border: 1px solid @panel-inner-border;
    opacity: 1;
  }
}

@media (max-width: @screen-sm) {
  .actions {
    .btn {
      border-radius: @border-radius-base !important;
      margin-left: 0px !important;
      margin-bottom: -1px;
      width: 100%;
    }
  }
}
