@import './variables.less';
@import './kyx.less';

.up-icon-path-mask(@icon) {
  mask-image: url('../../../assets/images/icons/kyx/@{icon}.svg');
  mask-size: 18px;
  background-repeat: no-repeat;
}

::ng-deep .up-icon {
  background-repeat: no-repeat;
  background-position: center;

  &.on-button {
    width: 24px;
    height: 24px;
    display: block;
    margin-top: -2px;
  }

  &.lock-dark {
    .up-icon-path-mask('lock');
    background-color: @icon-grey;
    mask-size: 15px;
    min-width: 15px;
    min-height: 15px;
  }
  &.unlock-dark {
    .up-icon-path-mask('unlock');
    background-color: @icon-grey;
    mask-size: 15px;
    min-width: 15px;
    min-height: 15px;
  }
  &.edit-dark {
    .up-icon-path-mask('edit');
    background-color: @icon-grey;
    mask-size: 12px;
  }
  &.trash-dark {
    .up-icon-path-mask('trash');
    background-color: @icon-grey;
    mask-size: 12px;
  }
  &.add-dark {
    .up-icon-path-mask('add');
    background-color: @icon-grey;
    mask-size: 12px;
    min-width: 12px;
    min-height: 12px;
    background-repeat: no-repeat;
  }
}
