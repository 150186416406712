@import './assets/less/vars';
@import '~bootstrap/less/mixins';
@import '~bootstrap/less/forms';

.deletedCell {
  text-decoration: line-through;
  color: @disabled;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.position-relative {
  position: relative;
}

.left-margin {
  margin-left: 20px;
}

.top-margin {
  margin-top: 20px;
}

.bottom-margin {
  margin-bottom: 20px;
}

.big-bottom-margin {
  margin-bottom: 40px;
}

.small-top-margin {
  margin-top: 10px;
}

.small-bottom-margin {
  margin-bottom: 10px;
}

.carousel-indicators {
  visibility: hidden;
}

.carousel.slide {
  img {
    width: 100%;
  }
}

.inline-gallery img.img-thumbnail {
  width: 150px;
  margin-right: 1em;
}

.stacked-gallery img.img-thumbnail {
  width: 150px;
  margin-top: 1em;
}

.stacked-gallery img.img-thumbnail:last-child {
  margin-bottom: 1em;
}

.stacked-gallery button.annotation {
  width: 150px;
}

legend {
  color: black;
  small {
    font-size: 15px;
    color: #c8c8c8;
  }
}

h1.page-title {
  margin-top: 30px;
  margin-bottom: 20px;
  color: #515151;
}

.ui-fileupload {
  display: inline-block;

  .ui-fileupload-buttonbar {
    background: #fff;
    border: 0;
  }
  .ui-fileupload-content {
    display: none;
  }
}

.row.v-center {
  display: flex;
  align-items: center;
}

@media (max-width: 767px) {
  .row.v-center {
    display: block;
    align-items: center;
  }
}

i.jumio-icon {
  background: url(assets/images/icons/new-jumio-icon-dark-16x16.png) no-repeat;
  width: 16px;
  height: 16px;
  display: inline-block;
  position: relative;
  top: 4px;
}

table i.jumio-icon {
  top: -4px;
}

.body-cell > .jumio-icon {
  top: 0px;
}

.grid-view {
  .col-sm-4 {
    padding: 10px 0px;
    border-bottom: 1px solid #ddd;
  }

  .row {
    border-top: 1px solid #ddd;
    :nth-child(6n + 1),
    :nth-child(6n + 2),
    :nth-child(6n + 3) {
      background-color: #f9f9f9;
    }
  }
}

app-single-datepicker .form-group .input-group .form-control {
  height: auto;
}

.equality-form .form-control.filter-expression {
  padding: 2px;
}

.fileinput {
  display: none !important;
}

.display-label {
  padding-top: 6px;
}

.image-dimensions {
  display: inline-block;
  line-height: 2.1em;
  padding-left: 1em;
}

.reset-to-default-btn {
  margin-left: 1em;
}

tr.with-groups {
  th.with-border,
  td.with-border {
    border-right: 1px solid #aaa;
  }
}

fieldset:disabled {
  cursor: not-allowed;
}

fieldset[disabled^='true'],
fieldset:disabled div {
  pointer-events: none;
}

img.img-thumbnail.popup {
  cursor: pointer;
}

h1.demo-app-title {
  background-color: #2e302f;
  color: @disabled;
  padding: 20px 10px;
}

div.demo-app-container {
  background-color: #f5f5f5;
}

.initFromLeft {
  animation: 1s ease-out 0s 1 slideInFromLeft;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

pre span {
  white-space: normal;
}

.string {
  color: green;
}

.number {
  color: darkorange;
}

.boolean {
  color: blue;
}

.null {
  color: magenta;
}

.key {
  color: red;
}

.modal .modal-danger .modal-header {
  background-color: #e61d17;
  border-radius: 5px 5px 0 0;
  h4 {
    color: #fff;
  }
}

.bordered {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
}

// keep some space for the vertical scrollbar
[appCollapsible] {
  width: 90%;
}

[appCollapsible].collapsed {
  height: 100px;
  overflow-y: scroll;
}

[appCollapsible].opened {
  height: 100%;
}

// these styles must be global since daterangepicker component just appends the popup window to the body element
select.hourselect,
select.minuteselect,
select.ampmselect {
  padding: 0;
}

textarea {
  resize: vertical;
}

app-paginator {
  width: 100%;
}

.ng2-tag-input {
  .form-control;
  height: 100%;
  padding-left: 3px !important;
  transition: none !important;
  &.ng2-tag-input--focused {
    @color-rgba: rgba(red(@input-border-focus), green(@input-border-focus), blue(@input-border-focus), 0.6);
    border-color: @input-border-focus;
    border-bottom: 2px solid #efefef !important;
    outline: 0;
    .box-shadow(~'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px @{color-rgba}');
  }
}

.reset-line-height {
  line-height: @jumio-line-height-base !important;
}

// set sortable link color in datatable
.ngx-datatable .datatable-header .datatable-header-cell.sortable .datatable-header-cell-wrapper {
  color: #98bf0d;
}

.align-cell-vertical-middle > tbody > tr > td {
  vertical-align: middle;
}

// Custom designed checkbox
.custom-checkbox {
  margin-top: 5px;
}

.body-cell .custom-checkbox {
  margin-top: 10px;
}

.custom-checkbox label {
  padding-left: 8px;

  & input[type='checkbox'] {
    display: none;
    &:disabled + span {
      opacity: 0.5;
      cursor: default;
    }
  }

  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

.custom-checkbox span {
  position: relative;
  display: inline-block;
  border: 1px white solid;
  border-radius: 0.25em;
  width: 1.3em;
  height: 1.3em;
  float: left;
  margin-right: 0.5em;
  cursor: pointer;

  & .glyphicon {
    position: absolute;
    font-size: 0.8em;
    line-height: 0;
    top: 50%;
    left: 15%;
  }

  &.primary {
    border-color: @brand-primary;
    color: @brand-success;
    width: 1.5em;
    height: 1.5em;
    & .glyphicon {
      font-size: 0.9em;
    }
  }
}

.deletedCell {
  text-decoration: line-through;
  color: @disabled;
}

.disabledCell {
  color: @disabled;
}

.ellipsize-left {
  /* Standard CSS ellipsis */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* Beginning of string */
  direction: rtl;
}

.no-wrap-label {
  white-space: nowrap;
}

.flexbox {
  display: flex;
}

.flexbox-row {
  flex-direction: row;
}

.flexbox-justify-content-center {
  justify-content: center;
}

.flexbox-justify-content-end {
  justify-content: end;
}

.flexbox-align-items-center {
  align-items: center;
}

.flexbox-row-wrap {
  flex-direction: row;
  flex-wrap: wrap;
}

.flexbox-column {
  flex-direction: column;
}

.flexbox-main-start {
  justify-content: start;
}

.flexbox-main-center {
  justify-content: start;
}

.flexbox-cross-start {
  align-items: start;
}

.flexbox-cross-end {
  align-items: start;
}

.gap-1rem {
  gap: 1rem;
}

.gap-2rem {
  gap: 2rem;
}

.p-1rem {
  padding: 1rem;
}

.p-bottom-1rem {
  padding-bottom: 1rem;
}

.p-8px {
  padding: 8px;
}

.hand-cursor {
  cursor: pointer;
}

.margin-left-auto {
  margin-left: auto;
}

.m-t-1rem {
  margin-top: 1rem;
}

.m-t-2rem {
  margin-top: 2rem;
}

.m-b-1rem {
  margin-bottom: 1rem;
}

.m-b-2rem {
  margin-bottom: 2rem;
}

.disable-text-selection {
  // Disable text selection in all browsers.
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.disable-pointers-events {
  pointer-events: none;
}

.width-100 {
  width: 100%;
}
