.modal {
  text-align: center;

  .modal-dialog {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
  }

  .modal-body {
    padding: @padding-base-horizontal * 1.5;
  }

  .modal-header {
    padding: @padding-base-horizontal @padding-base-horizontal * 1.5 @padding-base-horizontal @padding-base-horizontal * 1.5;
  }

  &.vertical.modal-dialog {
    vertical-align: middle;
  }

  &.modal-danger {
    .modal-header {
      background-color: darken(@brand-danger, 5%);
      border-radius: @border-radius-large - 1px @border-radius-large - 1px 0px 0px;
    }

    .modal-header h4 {
      color: white;
    }

    .modal-footer,
    .modal-header {
      border: none;
    }

    .form-control:focus {
      border-color: @brand-danger;
      box-shadow:
        inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 8px @brand-danger;
    }
  }

  &.modal-warn {
    .modal-header {
      background-color: darken(@brand-warning, 5%);
      border-radius: @border-radius-large - 1px @border-radius-large - 1px 0px 0px;
    }

    .modal-header h4 {
      color: white;
    }

    .modal-footer,
    .modal-header {
      border: none;
    }

    .form-control:focus {
      border-color: @brand-warning;
      box-shadow:
        inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 8px @brand-warning;
    }
  }

  &.modal-success {
    .modal-header {
      background-color: darken(@brand-primary, 5%);
      border-radius: @border-radius-large - 1px @border-radius-large - 1px 0px 0px;
    }

    .modal-header h4 {
      color: white;
    }

    .modal-footer,
    .modal-header {
      border: none;
    }

    .form-control:focus {
      border-color: @brand-warning;
      box-shadow:
        inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 8px @brand-warning;
    }
  }
}
