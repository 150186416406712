.popover {
  max-width: 320px;
}

.tooltip {
  font-size: @font-size-base;
  line-height: 1.5;
  .tooltip-inner {
    padding: 4px 8px;
  }
}
