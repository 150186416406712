.nav-tabs {
  li a {
    padding: 10px 10px;
    font-size: 13px;
  }
}

.nav-tabs,
.panel-header {
  .glyphicon {
    margin-right: @padding-base-vertical * 0.75;
    top: 1px;
  }
}
