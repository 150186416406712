@scale: 1.25;

.theme-smaller-input {
  .form-control {
    padding: (@padding-base-vertical / @scale);
    height: (@input-height-base / @scale);
  }
  .input-group-addon {
    padding-bottom: (@padding-base-vertical / @scale);
    padding-top: (@padding-base-vertical / @scale);
    padding-left: (@padding-base-horizontal / @scale);
    padding-right: (@padding-base-horizontal / @scale);
    height: (@input-height-base / @scale);
  }

  .btn {
    .button-size(
        (@padding-base-vertical / @scale); @padding-base-horizontal; @font-size-base; (@line-height-base / @scale); @border-radius-base
    );
  }

  .btn.btn-sm {
    padding: 5px 10px;
    font-size: 14px;
    line-height: 1.5;
    border-radius: 3px;
  }

  .btn.btn-xs {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
  }
}

@grid-gutter-width: 40px;
