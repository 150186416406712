.ngx-datatable {
  .datatable-header .datatable-header-cell.sortable .datatable-header-cell-wrapper {
    color: @brand-primary;
  }
  .datatable-body-cell-label {
    word-wrap: break-word;
    padding-right: 8px;
    max-width: 100%;
  }

  .datatable-footer .datatable-pager .pager li.disabled a {
    color: #999999;
  }

  &.bootstrap {
    .datatable-body .datatable-body-row .datatable-body-cell {
      display: flex;
      align-items: center;
    }

    .datatable-footer {
      background: white;
      color: @text-dark;

      .datatable-pager {
        margin: 0;

        i {
          display: flex;
        }

        ul {
          display: flex;

          li {
            margin: 0;

            &:not(.disabled) {
              &:hover a,
              &.active a {
                color: white;
                background: @brand-primary;
                text-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
              }
            }

            a {
              color: @text-dark;
              height: 41px;
              width: 41px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
  }
}

.table .glyphicon {
  margin-left: 4px;
  margin-right: 4px;
}

.glyphicon.large {
  font-size: 18px;
}

.table td > a {
  color: @text-color;
}

detail-group,
table {
  white-space: nowrap;
}
