@light-blue: #e6f3ff;
@blue: #3b66d4;

.kyx-datepicker {
  // Selected items are blue
  .mat-calendar-body-selected {
    border-color: @blue;
    background: @blue;
    color: white;
  }

  // Range items are light blue
  .mat-calendar-body-in-range {
    .mat-calendar-body-cell-preview {
      border-color: @light-blue;
      background: @light-blue;
    }
    .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
      color: @blue;
    }
  }

  .mat-calendar-body-range-start > .mat-calendar-body-cell-preview {
    border-radius: 50% 0 0 50%;
  }

  .mat-calendar-body-range-end > .mat-calendar-body-cell-preview {
    border-radius: 0 50% 50% 0;
  }

  // Today border is blue
  .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border-color: @blue;
  }

  // On hover item is light blue
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover,
  .cdk-keyboard-focused .mat-calendar-body-active,
  .cdk-program-focused .mat-calendar-body-active {
    & > .mat-calendar-body-cell-content {
      &:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
        background-color: rgba(59, 102, 212, 0.4) !important;
      }
    }
  }
}
