.filter-form {
  margin-right: -48px;
}

select {
  width: 100%;
  padding: 6px;
}

.filter-expression {
  width: 25%;
}

.filter-value {
  width: 73%;
  float: right;
}

.form-group {
  &.equality-form {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 0 !important;
    display: flex;
  }
}

.filter-expression,
.filter-value {
  display: inline-block;
}

.filter-expression {
  width: 50% !important;
  margin-right: 10px;
}

input {
  border-radius: 4px;
}

.input-component {
  float: left;
  padding-right: 32px;
  width: 294px;
  margin-top: 8px;
  .btn {
    width: 49%;
    height: 35px;
    line-height: 20px;
    padding: 0px;
  }
}

.form-control {
  height: 32px;
}

// select height should not be overwritten
select.form-control {
  height: inherit;
}

.panel .input-component {
  width: 280px;
}
