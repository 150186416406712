.or {
  text-align: center;
  height: 16px;
  position: relative;
  margin-bottom: 16px;

  .text {
    position: absolute;
    display: block;
    background: white;
    color: @gray-light;
    width: 32px;
    height: 32px;
    margin-top: -10px;
    margin-left: -16px;
    top: 50%;
    left: 50%;
  }

  .line {
    position: absolute;
    display: block;
    border-bottom: 1px solid @gray-lighter;
    width: 100%;
    height: 1px;
    top: 50%;
  }
}
