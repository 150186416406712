@import '~bootstrap/less/bootstrap';

.form-group {
  label {
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
    float: left;
  }
  &.detail {
    > label {
      .make-sm-column(3);
    }
    .input-field {
      .make-sm-column(9);
      .make-md-column(7);
      .make-lg-column(7);
      &.empty-offset {
        .make-sm-column-offset(3);
      }
      &.with-text {
        .make-sm-column(1);
        .make-md-column(1);
        .make-lg-column(1);
      }
      &.wider {
        .make-lg-column(5);
      }
      > .pad-top {
        padding-top: 6px;
      }
    }
  }

  &.form-actions {
    .input-field {
      .make-sm-column(9);
      .make-md-column(9);
      .make-lg-column(9);
    }
  }
}

.auto-height-input.theme-smaller-input {
  .form-control {
    height: auto;
  }
}

.form-title,
.form-button {
  .make-sm-column-offset(3);
  .pad-left {
    padding-left: 20px;
  }
}

.form-horizontal {
  app-form-group-tag-field {
    .control-label {
      padding-top: 12px !important;
    }
  }

  .control-label,
  .radio-inline {
    padding-top: 6px !important;
    &.tag {
      padding-top: 12px !important;
    }
  }
}

.input-group-btn:last-child > .btn {
  height: 36.8px;
}
