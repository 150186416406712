@import './variables.less';

body {
  font-family: 'Roboto', sans-serif;
  color: @text-color;
  letter-spacing: @letter-spacing;
}

.display-flex {
  display: flex;
}

.flex-align-center {
  align-items: center;
}

.flex-1 {
  flex: 1;
}

.page-container {
  padding: 0 16px;
}

.column-flex {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.column-flex-container {
  .column-flex;
  height: 100%;
}

.daterangepicker {
  z-index: 99999;
  box-shadow: @box-shadow;
}

.page-title {
  color: @text-color;
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
  margin: 16px 0;

  &.has-back {
    display: flex;
    align-items: center;

    .page-label {
      margin-left: 16px;
    }
  }
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.position-relative {
  position: relative;
}

.color-picker {
  border: 1px solid @subtle-text-color !important;
  box-shadow: @box-shadow;
}

.overflow-hidden {
  overflow: hidden;
}

.margin-top {
  margin-top: 16px;
}

.margin-bottom {
  margin-bottom: 16px;
}

.margin-right {
  margin-right: 10px;
}

.margin-left {
  margin-left: 10px;
}

.fas {
  font-size: 14px;
}

a {
  color: @primary;
}

.title {
  margin-bottom: 16px;
  color: @text-color;
  font-weight: bold;
  font-size: 18px;
}

.icon-container {
  color: @subtle-text-color;
  width: 29px;
  height: 32px;
  padding: 8px;
  border-radius: 4px;

  &.icon-delete:hover {
    background: @error-color;
    color: white;
  }

  &.icon-default:hover {
    background: @hover;
  }
}
