@import './variables.less';
@import './general.less';
@import './forms.less';
@import './buttons.less';
@import './data-block.less';
@import './typeahead.less';
@import './pill.less';
@import './table.less';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import './kyx-datepicker.style.less';
