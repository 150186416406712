@import './variables.less';

.form-block {
  .fields {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 16px;

    .field-container {
      width: 50%;
      color: @subtle-text-color;
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;

      &:nth-child(2n) {
        padding-left: 8px;
      }

      &:nth-child(2n + 1) {
        padding-right: 8px;
      }

      @media screen and (max-width: 1400px) {
        flex-basis: 100%;
        padding: 0;

        &:nth-child(n) {
          padding: 0;
        }
      }

      .placeholder-field {
        height: 100%;
        display: flex;
        font-size: 15px;
        color: @subtle-text-color;
        justify-content: space-between;
        align-items: center;
        background: @secondary;
        border-radius: @border-radius;
        align-items: space-between;
        padding: 0 16px;
      }
    }
  }
}
