.navbar-header {
  display: none;
}

@media (max-width: @screen-xs) {
  .navbar .glyphicon {
    margin-right: -20px;
  }

  .navbar .text {
    display: none;
  }
}
