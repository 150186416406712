@font-face {
  font-family: 'glyphicon-more';
  src: url('../../../fonts/glyphicon-more.eot?-3cya89');
  src:
    url('../../../fonts/glyphicon-more.eot?#iefix-3cya89') format('embedded-opentype'),
    url('../../../fonts/glyphicon-more.ttf?-3cya89') format('truetype'),
    url('../../../fonts/glyphicon-more.woff?-3cya89') format('woff'),
    url('../../../fonts/glyphicon-more.svg?-3cya89#glyphicon-more') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='glyphicon-more'],
[class*=' glyphicon-more'] {
  font-size: 16px;
  font-family: 'glyphicon-more', serif;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.glyphicon-more-file-text:before {
  content: '\e922';
}

.glyphicon-more-profile:before {
  content: '\e923';
}

.glyphicon-more-file-empty:before {
  content: '\e924';
}

.glyphicon-more-file-text2:before {
  content: '\e926';
}

.glyphicon-more-android:before {
  content: '\e600';
}

.glyphicon-more-apple:before {
  content: '\eabf';
}

.glyphicon-more-google:before {
  content: '\ea87';
}

.glyphicon-more-google-plus:before {
  content: '\ea88';
}

.glyphicon-more-google-plus2:before {
  content: '\ea89';
}

.glyphicon-more-google-plus3:before {
  content: '\ea8a';
}
