@import './variables.less';

.data-block {
  margin-top: 16px;
  font-size: 12px;
  color: @text-color;

  .data-row {
    display: flex;
    padding: 8px 24px;

    &:nth-child(2n) {
      background: @secondary;
    }

    .data-label {
      width: 30%;
    }

    .value {
      flex: 1;
      color: @text-color;
      font-weight: 600;
    }

    .show-btn {
      color: @primary;
      margin-left: 16px;
      cursor: pointer;
    }
  }
}
