footer {
  margin-bottom: @padding-base-vertical * 2;

  a,
  span {
    color: @gray-light;
  }
  a:hover {
    color: @gray;
  }
}
