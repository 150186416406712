@import './variables.less';

.button-group {
  display: flex;

  .button:not(:last-child) {
    margin-right: 16px;
  }
}

.button {
  padding: 0 16px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: @button-secondary-bg;
  border-radius: @border-radius;
  border: none;
  color: @button-secondary-text;
  font-weight: 550;

  &.button-primary {
    background: @button-primary-bg;
    color: @button-primary-text;

    &:hover {
      background: @button-primary-hover-bg;
      color: @button-primary-text;
    }

    &:active {
      background: @button-primary-bg;
      color: @button-primary-text;
    }

    &:disabled,
    &.disabled {
      background: @button-primary-disabled-bg;
      color: @button-primary-hover-bg;
    }
  }

  &.button-default {
    background: @button-default-bg;
    color: @button-default-text;

    &:hover {
      background: @button-default-hover-bg;
      color: @button-default-text;
    }

    &:active,
    &.active {
      background: @button-active-bg;
      color: @button-active-color;
    }

    &:disabled,
    &.disabled {
      background: @button-default-disabled-bg;
      color: @button-secondary-disabled-text;
    }
  }

  &.button-small {
    height: 20px;
    padding: 0 8px;
  }

  &.button-inline {
    display: inline-block;
    margin-left: 8px;
  }

  &:hover {
    background: @button-secondary-hover-bg;
    color: @button-secondary-text;
  }

  &:active,
  &.active {
    background: @button-active-bg;
    color: @button-active-color;
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;
    background: @button-secondary-disabled-bg;
    color: @button-secondary-disabled-text;
  }

  .fas + span {
    margin-left: 8px;
  }

  &.button-negative {
    &:hover {
      background: @error-light-color;
      color: @error-color;
    }
  }

  &.back-button .fa-angle-left {
    top: -1px;
  }
}

button.hover-green:not(:disabled):hover {
  background-color: @success-color;
  color: @text-terciary-color;
}

button.hover-red:not(:disabled):hover {
  background-color: @error-color;
  color: @text-terciary-color;
}
