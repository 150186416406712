@breadcrumb-padding-vertical: 0;
@breadcrumb-padding-horizontal: 0;
@breadcrumb-color: lighten(@gray, 20%);
@breadcrumb-active-color: lighten(@gray-dark, 10%);
@breadcrumb-separator: '/';

.breadcrumb {
  border: 0;
  margin: 0;
}

.breadcrumb a {
  color: @breadcrumb-color;
}

.breadcrumb a:hover {
  color: @breadcrumb-active-color;
}

.breadcrumb .active {
  &,
  & a {
    color: black;
    text-decoration: none;
  }
}

.breadcrumb {
  background: white;
  font-size: 24px;
  padding: 0;
}

.breadcrumb > li + li:before {
  color: lighten(@breadcrumb-color, 10%);
}

@media (max-width: @screen-sm) {
  .breadcrumb {
    &,
    & * {
      font-size: @font-size-mobile * 1.25;
      line-height: @font-size-mobile * 1.25 * 2;
    }
  }
}
