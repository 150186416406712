@import './variables.less';

@header-bar-shadow: #eef1f5;
@header-bar-text: #69738a;
@header-bar-height: 56px;

@container-bg: #f9f9fb;
@container-height: calc(100vh - @header-bar-height);
@container-height-with-secondary-header: calc(100vh - (2 * @header-bar-height));

@text-color: #252d38;

@error-color: #cb5252;
@warning-color: #ffe082;
@success-color: #60b169;
@info-color: #389dbc;
@pending-color: #69738a;

@link-text-color: #3b66d4;
@link-text-color-hover: darken(#3b66d4, 25%);

@focus-border-color: #a5ceff;
@border-color: #dfe0eb;

@panel-border-color: #e4eaf1;
@panel-border-color-active: #6e7291;
@panel-border-radius: 4px;
@panel-box-shadow: 0px 2px 8px rgba(63, 66, 90, 0.1);
@panel-header-bg-color: #fcfcfd;
@panel-divider-color: #dfe0eb;
@narrow-screen-threshold: 768px;

@panel-body-content-label-color: #8194a2;
@panel-body-content-value-color: #252d38;
@input-border-color: #cecfdf;
@button-secondary-bg: #fdfdfd;
@button-secondary-text: #4c5568;
@button-secondary-text-disabled: #959eb6;
@button-secondary-shadow: 0 2px 3px rgba(0, 0, 0, 0.10196078431372549); // Mimicing the original JTM button designs

@risk-red: #f79696;
@risk-red-mark: #d64141;
@risk-yellow: #ffe082;
@risk-yellow-mark: #d78e00;
@risk-green: #6ce092;
@risk-green-mark: #008a57;

@risk-grey-1: #f3f4f6;
@risk-grey-2: #ced2db;
@risk-grey-3: #8993a7;
@risk-grey-mark: #5e687d;

@icon-grey: #69738a;
@input-border-color: #cecfdf;
@idp-grey: #8194a2;

@toggle-button-bg-on: @link-text-color;
@toggle-button-bg-on-hover: #5b89ff;
@toggle-button-bg-off: #e6e7ef;
@toggle-button-bg-off-hover: #d0d7e1;

@dropdown-background-color: #fbfcfe;
@dropdown-background-color-hover: #eef1f5;
@dropdown-border-color: #d7dae8;

@checkbox-border-color: #eeeeee;

.kyx-box-shadow(@top, @left, @blur, @color) {
  box-shadow: @top @left @blur @color;
  -moz-box-shadow: @top @left @blur @color;
  -webkit-box-shadow: @top @left @blur @color;
  -o-box-shadow: @top @left @blur @color;
}

.kyx-box-shadow-inset(@top, @left, @blur, @color) {
  box-shadow: inset @top @left @blur @color;
  -moz-box-shadow: inset @top @left @blur @color;
  -webkit-box-shadow: inset @top @left @blur @color;
  -o-box-shadow: inset @top @left @blur @color;
}

.kyx-icon-path(@icon) {
  background-image: url('../../../assets/images/icons/kyx/@{icon}.svg');
}

.kyx-icon-path-mask(@icon) {
  mask-image: url('../../../assets/images/icons/kyx/@{icon}.svg');
  mask-size: 18px;
  background-repeat: no-repeat;
}

div.kyx-container {
  background-color: @container-bg;
  overflow-y: auto;
  padding: 16px;
  height: 100vh;
  display: flex;

  &.with-header {
    height: @container-height;
  }

  &.with-secondary-header {
    height: @container-height-with-secondary-header;
  }
}

.page-container {
  &.with-header {
    height: @container-height;
    max-height: @container-height;
  }

  &.with-secondary-header {
    height: @container-height-with-secondary-header;
    max-height: @container-height-with-secondary-header;
  }
}

::ng-deep .kyx-icon {
  background-repeat: no-repeat;
  background-position: center;

  &.on-button {
    width: 24px;
    height: 24px;
    display: block;
    margin-top: -2px;
  }

  &.add {
    .kyx-icon-path('add');
  }
  &.url-icon {
    .kyx-icon-path('url');
  }
  &.about {
    .kyx-icon-path('about');
  }
  &.external-link {
    .kyx-icon-path('external-link');
  }
  &.reports {
    .kyx-icon-path('reports');
  }
  &.settings {
    .kyx-icon-path('settings');
  }
  &.sign-out {
    .kyx-icon-path('sign-out');
  }
  &.transactions {
    .kyx-icon-path('transactions');
  }
  &.user {
    .kyx-icon-path('user');
  }
  &.users {
    .kyx-icon-path('users');
  }
  &.copy {
    .kyx-icon-path('copy');
  }
  &.users-dark {
    .kyx-icon-path-mask('users');
    background-color: @icon-grey;
    margin-top: 5px;
  }
  &.add-dark {
    .kyx-icon-path-mask('add');
    background-color: @icon-grey;
    mask-size: 12px;
    min-width: 12px;
    min-height: 12px;
    background-repeat: no-repeat;
  }
  &.idp-dark {
    .kyx-icon-path-mask('idp');
    background-color: @icon-grey;
  }
  &.lock-dark {
    .kyx-icon-path-mask('lock');
    background-color: @icon-grey;
    mask-size: 15px;
    min-width: 15px;
    min-height: 15px;
  }
  &.unlock-dark {
    .kyx-icon-path-mask('unlock');
    background-color: @icon-grey;
    mask-size: 15px;
    min-width: 15px;
    min-height: 15px;
  }
  &.edit-dark {
    .kyx-icon-path-mask('edit');
    background-color: @icon-grey;
    mask-size: 12px;
  }
  &.trash-dark {
    .kyx-icon-path-mask('trash');
    background-color: @icon-grey;
    mask-size: 12px;
  }
  &.download-icon {
    .kyx-icon-path-mask('download');
    background-color: @icon-grey;
    mask-size: 12px;
  }
  &.settings-dark {
    .kyx-icon-path-mask('settings');
    background-color: @icon-grey;
    min-width: 18px;
    min-height: 18px;
    background-repeat: no-repeat;
  }
  &.identity-verified {
    .kyx-icon-path-mask('identity-verified');
    background-color: @icon-grey;
    min-width: 18px;
    min-height: 18px;
    background-repeat: no-repeat;
  }

  &.verifications {
    .kyx-icon-path('verifications');
  }
  &.kyx-trash-icon {
    .kyx-icon-path('cleanicon');
  }
}

.kyx-filter-icon {
  .kyx-icon-path-mask('filter');
  width: 13px;
  height: 13px;
  mask-size: 13px;
  background-color: #69738a;
  margin-right: 5px;
  margin-top: -2px;
}

::ng-deep div.kyx-table {
  position: relative;

  & > jtm-simple-table jtm-pagination jtm-dropdown {
    margin-top: 15px;
    & .dropdown-content {
      top: -11px;
    }
  }
}

::ng-deep form div.kyx-form {
  &.two-columns {
    display: flex;
    flex-direction: column;
  }
}

::ng-deep select.kyx-dropdown {
  height: 32px;
  font-weight: 500;
  background-color: @dropdown-background-color;
  border: 1px solid @dropdown-border-color;
  border-radius: 4px;
  padding: 0 5px;
  font-size: 14px;
  color: @button-secondary-text;
  cursor: pointer;

  &:hover {
    background-color: @dropdown-background-color-hover;
  }
  & > option {
    &:hover {
      background: @dropdown-background-color-hover;
    }
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
}

::ng-deep app-data-table.kyx-data-table {
  & .data-table-container {
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(46, 46, 75, 0.0392156862745098); // Coming from JTM library 1:1
    border: 1px solid @panel-border-color;

    & app-header {
      background-color: @panel-header-bg-color !important;
      & div.header-container .header {
        background-color: @panel-header-bg-color !important;
        text-align: left;
        font-weight: 400 !important;
        font-size: 14px !important;
        color: @button-secondary-text;
        height: 56px;

        & .header-cell {
          &:hover {
            background-color: transparent !important;
          }
          & .custom-checkbox {
            margin-top: 10px;
            & span {
              border-width: 3px;
              border-radius: 2px;
              border-color: @checkbox-border-color;
              &.selected {
                border-color: @link-text-color;
                background-color: @link-text-color;
                & > i {
                  margin-top: -2px;
                  &.fa-minus {
                    margin-left: 1px;
                  }
                  &.fa-check {
                    margin-left: 0;
                  }
                }
              }
            }
          }
        }
      }
    }

    & app-data-table-paginator .paginator {
      height: 54px !important;
      background-color: @panel-header-bg-color !important;
      margin-bottom: 0 !important;
      padding-top: 12px !important;

      & .paginator-label {
        font-weight: 400 !important;
        font-size: 14px !important;
        color: @button-secondary-text;
      }

      & app-dropdown button {
        border: 1px solid @dropdown-border-color;
        &:hover {
          background-color: initial !important;
          color: initial !important;
        }
      }
    }
  }
}

::ng-deep app-form-group-checkbox.kyx-checkbox {
  & div.custom-checkbox.disabled {
    cursor: not-allowed !important;
  }

  & span.custom-checkbox-field {
    border-width: 3px;
    border-radius: 2px;
    border-color: @checkbox-border-color;
    &.selected {
      border-color: @link-text-color;
      background-color: @link-text-color;
      & > i {
        line-height: 13px !important;
        padding-left: 0 !important;
      }
    }
  }
}

::ng-deep app-form-group-switch.kyx-form-group-switch {
  margin-left: -8px;

  &.disabled {
    cursor: not-allowed;
  }

  &.with-indent {
    margin-left: auto;
    & div.form-group-switch-container {
      padding-left: initial !important;
    }
  }

  & div.form-group-switch-container {
    background: transparent !important;
    padding-left: 0 !important;
    & input.form-group-switch {
      width: 32px !important;
      height: 18px !important;
      background: @toggle-button-bg-off !important;
      border: 1px solid @toggle-button-bg-off !important;
      &:hover {
        background: @toggle-button-bg-off-hover !important;
      }

      &:after {
        top: 1px !important;
        left: 2px !important;
        width: 14px !important;
        height: 14px !important;
        background: white !important;
      }

      &:checked {
        background: @toggle-button-bg-on !important;
        border: 1px solid @toggle-button-bg-on !important;
        &:hover {
          background: @toggle-button-bg-on-hover !important;
        }
        &:after {
          left: 15px !important;
        }
      }

      &:disabled {
        opacity: 0.3;
        cursor: not-allowed !important;
      }
    }
  }
}

::ng-deep app-data-table.kyx-table {
  & app-header > .header-container > .header {
    background-color: @panel-header-bg-color !important;
    border: 1px solid @panel-border-color;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    font-size: 14px;
    font-weight: 500;
  }
  & app-list-view {
    & > div {
      margin-bottom: 20px !important;
      border: 1px solid @panel-border-color;
      border-top: 0;
    }
  }
}

::ng-deep j4-alert {
  & .alert-container.kyx-alert {
    padding-left: 0 !important;

    & .alert-icon {
      background: @toggle-button-bg-on;
      color: white;
      font-weight: bold;
      font-size: 12px;
    }
  }
}

::ng-deep a.kyx-link {
  color: @link-text-color;
  margin-left: 10px;
  text-decoration: underline;
  &:hover,
  &:focus,
  &:visited {
    color: @link-text-color-hover;
    & > .link-icon {
      background-color: @link-text-color-hover;
    }
  }
  & > .link-icon {
    margin-left: 10px;
  }
}

::ng-deep jtm-simple-table.no-action-table > div > table > tbody > tr {
  pointer-events: none;
}

::ng-deep form div.kyx-form {
  & hr {
    width: 100%;
  }

  & .form-row.flex-row {
    display: flex;
    flex-direction: row;
    & > * {
      display: flex;
      width: 100%;
    }
    &.slim > div:first-child {
      max-width: 300px;
    }
  }

  &.two-columns {
    display: flex;
    flex-direction: column;

    & > .form-row {
      display: flex;
      flex-direction: row;

      &:not(:last-child) {
        margin-bottom: 24px;
        &.no-margin {
          margin-bottom: auto;
        }
      }

      & > app-form-group-switch ~ span {
        line-height: 40px;
      }

      & > .form-input-label,
      & > .form-input-component {
        width: 100%;
        max-width: 240px;
        &.wide {
          max-width: 100%;
        }
        &.wide-flex {
          display: flex;
          max-width: 100%;
          width: 100%;
          & > app-form-group-input {
            width: 100%;
          }
        }
      }
      & > .form-input-label {
        line-height: 32px;
        font-size: 14px;
        color: @panel-body-content-label-color;
        &.input-suffix,
        &.input-comment {
          margin-left: 8px;
        }
        &.input-suffix {
          color: @text-color;
        }
      }
      & > .form-input-component {
        line-height: 20px;
        height: 32px;
        & input {
          height: 32px;
          border-radius: 4px;
          border-color: @input-border-color;
          &::placeholder {
            color: @header-bar-text;
          }
        }
      }
    }
  }
}

::ng-deep {
  app-form-group-input {
    .form-group-input {
      &::placeholder {
        color: @header-bar-text !important;
      }
    }
  }
}

::ng-deep .kyx-sticky-page-submit-button {
  position: absolute;
  top: 12px;
  right: 16px;
}

::ng-deep app-form-group-switch ~ span.form-group-switch-text {
  line-height: 3em;
}

::ng-deep app-form-group-input.show-full-error .alert-message {
  position: absolute;
}
