p.large {
  font-size: @font-size-base * 1.2;
}

@media (max-width: @screen-sm) {
  a,
  span,
  td,
  tr,
  th,
  h3,
  p,
  label,
  .btn,
  .form-control {
    font-size: @font-size-mobile;
  }
}

.dropdown-menu a {
  overflow: hidden;
  text-overflow: ellipsis;
}

.breadcrumb {
  font-size: 28px;
}

h2,
.h2 {
  font-size: 24px;
  color: black;
}
