// Bootstrap Core
@import '~bootstrap/less/bootstrap.less';

// Font-Awesome
@import '~@fortawesome/fontawesome-free/css/all.css';

// ngx-datatable
@import 'node_modules/@swimlane/ngx-datatable/index.css';

// TODO Temporary bootstrap CSS for datatable, can be removed later when real design is ready
@import 'node_modules/@swimlane/ngx-datatable/themes/bootstrap.css';
@import 'node_modules/@swimlane/ngx-datatable/assets/icons.css';

@import '~bootstrap-daterangepicker/daterangepicker.css';

// Project Components
@import 'import.less';

// Style
@import 'vars.less';
