typeahead-container {
  left: 0 !important;
  top: ~'calc(10% + 5px)' !important;

  &.dropup {
    top: -5px !important;
  }

  .dropdown-menu {
    box-shadow: @box-shadow;
    background: #fff;
    border: 0;
    border-radius: @border-radius;
    padding: 0;
    margin: 0;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }

    li {
      a {
        padding: 8px 16px;
      }

      &.active a {
        background: @primary-light !important;
        color: @primary !important;
      }
    }
  }
}
