@import './variables.less';

.table-cell {
  min-height: @table-row-height;
  padding: @table-item-padding;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.data-table-container {
  .body {
    padding: @table-row-padding;
    overflow-y: scroll;
    overflow-x: hidden;
    flex: 1;

    -webkit-padding-end: 9px;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }

    &.macOS-moz-padding {
      -moz-padding-end: 16px;
    }

    .placeholder {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
