button:focus,
span:focus {
  outline: 0 !important;
}

a button {
  &,
  &:hover,
  &:focus {
    color: lighten(@gray, 5%);
  }
}

.btn {
  .button-size(@padding-base-vertical; @padding-base-horizontal; @font-size-base; @line-height-base; @border-radius-base);
  box-shadow: 0 1px 4px @gray-lighter;

  &:focus,
  &:active {
    outline: 0;
  }
}

.btn-link {
  &,
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.btn-sm {
  .button-size(@padding-small-vertical; @padding-small-horizontal; @font-size-base; @line-height-small; @border-radius-small);
}

.btn-primary {
  .button-variant(@btn-primary-color; @btn-primary-bg; @btn-primary-bg);
}

.btn .glyphicon.large {
  margin: -4px 4px;
  font-size: 20px;
  top: 4px;
  display: inline-block;
}

.btn .glyphicon {
  margin: -4px 4px;
  font-size: 16px;
  top: 3px;
  display: inline-block;
}

.btn-group {
  .btn {
    box-shadow: none;
  }
}
