// Simple padding classes

.pad-half {
  padding-left: (@grid-gutter-width / 4);
  padding-right: (@grid-gutter-width / 4);
}

.pad-left {
  padding-left: (@grid-gutter-width / 2);
}

.pad-right {
  padding-right: (@grid-gutter-width / 2);
}

// Simple fixes

.center-vertical {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.block {
  display: block;
}

.wrap {
  white-space: normal;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
  z-index: 9999;
}

.center {
  text-align: center;
}

.clear {
  clear: both;
}
