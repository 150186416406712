@ibm-plex-font: 'IBM Plex Sans';

@font-face {
  font-family: @ibm-plex-font;
  font-weight: 400;
  font-style: normal;
  src: url('./../../assets/fonts/ibm-plex/IBMPlexSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: @ibm-plex-font;
  font-weight: 400;
  font-style: italic;
  src: url('./../../assets/fonts/ibm-plex/IBMPlexSans-Italic.ttf') format('truetype');
}

@font-face {
  font-family: @ibm-plex-font;
  font-weight: 500;
  font-style: normal;
  src: url('./../../assets/fonts/ibm-plex/IBMPlexSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: @ibm-plex-font;
  font-weight: 600;
  font-style: normal;
  src: url('./../../assets/fonts/ibm-plex/IBMPlexSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: @ibm-plex-font;
  font-weight: 700;
  font-style: normal;
  src: url('./../../assets/fonts/ibm-plex/IBMPlexSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: @ibm-plex-font;
  font-weight: 700;
  font-style: italic;
  src: url('./../../assets/fonts/ibm-plex/IBMPlexSans-BoldItalic.ttf') format('truetype');
}

html body,
option {
  font-family: @ibm-plex-font, sans-serif;
}

html body {
  // Make sure that scroll bar is always visible everywhere
  cdk-virtual-scroll-viewport {
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }
}

.jtm-tooltip {
  max-width: 400px;
  position: absolute;
  font-size: 14px;
  text-align: center;
  color: #f8f8f2;
  padding: 5px 10px;
  background: #333333;
  border-radius: 2px;
  z-index: 1000;
  opacity: 0;

  &.show {
    opacity: 1;
    transition: opacity 0.2s linear;
  }
}
